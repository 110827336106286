@font-face {
  font-family: "NetcoIcon";
  src: url("../fonts/IconosNetcoSingerFont-Regular.eot");
  src: url("../fonts/IconosNetcoSingerFont-Regular.woff") format("woff"),
  url("../fonts/IconosNetcoSingerFont-Regular.otf") format("opentype"),
  url("../fonts/IconosNetcoSingerFont-Regular.ttf") format("ttf");
}

.netco-icon {
  font-family: NetcoIcon, Arial, serif;
  font-size: 20px;
  margin-left: -5px;
  font-style: normal;
}