.my-password-field
{
   -webkit-text-security: disc;
}
textarea {
    resize: none;
}

.ant-input-password-icon {
    margin-right: 15px;
}

.ant-form-item
{
    margin-bottom: 0px;
}

.no-icon .ant-form-item-children-icon {
    display: none;
}

.ant-dropdown .bg-white {
    background-color: #FFFFFF;
    border: 1px solid lightgray;
}

.ant-card-head, .ant-table-title {
    border: none !important;
}


/*CARD*/
.ant-card-body .title-info .title {
    color: #8a8a8a
}

.ant-card-body .title-info .info {
    background-color: #f8f8f8;
    color: #9a9a9a;
    border: none;
    font-size: 15px;
}

/*----------------------*/

.ant-checkbox-wrapper {
    line-height: 20px;
}


.ant-btn.ant-btn-primary {
    background-color: dodgerblue;
    border: none;
    color: #FFFFFF;
    border-radius: 10px;
    height: 45px;
    max-width: 300px;
}

.ant-btn.ant-btn-ghost {
    background-color: #4894BF;
    border: none;
    color: #FFFFFF;
    border-radius: 20px;
}

.ant-btn.ant-btn-danger {
    border-radius: 20px;
}

.ant-legacy-form-item{
    margin:0;
}
.ant-menu-horizontal>.ant-menu-item:hover
{
    color:white;
    border-bottom: 2px solid transparent;
}
.ant-menu-horizontal
{
    border-bottom: 2px solid transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected
{
    background-color: #514e5c;
}
.ant-row, .ant-row:after, .ant-row:before
{
    display: flex;
}
.steps-content
{
    width:100%;
}
.ant-legacy-form-item-control-wrapper
{
    flex:100%;
}
.flexRight
{
    justify-content: flex-end;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
    border-radius: 4px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector
{
    height: 42px;
}
.ant-table-wrapper
{
    width:100%;
}
.ant-picker-now-btn
{
    display: none;
}
.ant-input-affix-wrapper
{
    padding: 0;
    border-radius: 4px;
    height: 40px;
}
.ant-input-affix-wrapper>input.ant-input
{
    padding-left:30px;
}
.ant-input-prefix
{
    padding-top: 14px;
    position: absolute;
    z-index:2;
    font-size: 14px;
    padding-left: 11px;
}
.ant-input-suffix
{
    padding-top: 14px;
    position: absolute;
    z-index:2;
    font-size: 14px;
    right: 12px;
}
.netcoIconPadding .ant-input-prefix
{
    padding-top:5px;
}
.netcoConfig .ant-legacy-form-item-control
{
    line-height: 22px;
}
