.ant-modal .ant-modal-content, .ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 15px;
}

.ant-modal .ant-modal-content .ant-modal-header {
  border: none;
  color: #8a8a8a;
  font-size: 20px;
}

.ant-modal .ant-modal-content .ant-modal-title {
  color: #8a8a8a;
  font-size: 20px;
}

.ant-modal .ant-modal-body {
  padding-top: 0;
}

.ant-modal .ant-modal-body label {
  color: #8a8a8a;
}
.canvasBorder
{
  border: dashed;
  width:100%;
  /*max-width: 720px;*/
}
