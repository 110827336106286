.ant-btn {
  white-space: normal;
  line-height: 15px;
  margin-bottom: 10px;
}

.rounded {
  border-radius: 20px;
  width: 50%;
  height: 40px
}

.rounded-sm {
  border-radius: 20px;
  width: 30%;
  height: 30px
}

.light-blue {
  background-color: #60C5FF;
  border: none;
  color: #FFFFFF;
}

.middle-blue {
  background-color: #4894BF;
  border: none;
  color: #FFFFFF;
}

.dark-blue {
  background-color: #306380;
  border: none;
  color: #FFFFFF;
}