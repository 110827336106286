.public-layout {
    color: #efefef !important;
}

.public-layout .public-card {
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.public-layout .ant-card-head {
    border: none
}

.public-layout .ant-card-head-title {
    padding-bottom: 0
}

.public-layout .ant-card-head-title img {
    height: 90px;
    margin-top: 20px;
}

.public-layout .ant-card-body {
    padding-top: 0
}

.public-layout .ant-alert {
    background-color: transparent;
    border: none
}